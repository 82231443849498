import Vue, { PropType } from 'vue'
import { SortMenuItem } from '../types'
import { SORT_OPTIONS } from '../constants'

export default Vue.extend<any, any, any, any>({
  props: {
    value: Object as PropType<SortMenuItem>,
  },
  data () {
    return {
      SORT_OPTIONS,
      isMenuOpened: false,
    }
  },
  computed: {
    isMenuActive () {
      return this.value?.name && this.value?.queryValue !== 'date-desc'
    },
  },
  methods: {
    onShow () {
      this.isMenuOpened = true
    },
    onHide () {
      this.isMenuOpened = false
    },
    onSortInput (sort: SortMenuItem) {
      if (sort) {
        this.$emit('input', sort)
      }
    },
  },
})
