export const SORT_FEATURED = {
  name: 'Newest',
  field: 'featured',
  dir: 'desc',
}

export const SORT_NEW = {
  field: 'date',
  dir: 'desc',
}

export const VACANCIES_LIMIT = 38

export const AVG_SKILLS = [
  'blockchain-and-crypto',
  'design-and-creative',
  'sales-and-marketing',
  'writing',
  'development',
  'nft',
  'gaming',
  'audio-and-video',
]

export const SORT_OPTIONS = [
  {
    name: 'Newest',
    value: 'date',
    direction: 'desc',
    queryValue: 'date-desc',
  },
  {
    name: 'Oldest',
    value: 'date',
    direction: 'asc',
    queryValue: 'date-asc',
  },
]

export const MAX_BUDGET = 180000
export const MIN_BUDGET = 0
export const DEFAULT_BUDGET = [ MIN_BUDGET, MAX_BUDGET ] as const
