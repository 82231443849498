import Vue, { PropType } from 'vue'
// @ts-ignore
import { mixin as clickaway } from 'vue-clickaway2'
import { formatUsd } from '@/utils/moneyFormat'
import { SalaryMenuItem } from '../types'
import { SORT_OPTIONS, MIN_BUDGET, MAX_BUDGET } from '../constants'

export function clearPriceInputOpened () {
  return {
    from: false,
    to: false,
  }
}

export default Vue.extend<any, any, any, any>({
  mixins: [clickaway],
  props: {
    value: Array as PropType<Array<Number>>
  },
  data () {
    return {
      MIN_BUDGET,
      MAX_BUDGET,
      SORT_OPTIONS,
      isMenuOpened: false,
      priceInputOpened: clearPriceInputOpened(),
    }
  },
  computed: {
    isMenuActive () {
      const bFrom = !Number.isNaN(this.value[0]) && this.value[0] > MIN_BUDGET
      const bTo = !Number.isNaN(this.value[1]) && this.value[1] > MIN_BUDGET && this.value[1] !== MAX_BUDGET
      return bFrom || bTo
    },
  },
  methods: {
    formatUsd,
    onShow () {
      this.isMenuOpened = true
    },
    onHide () {
      this.isMenuOpened = false
      this.priceInputOpened = clearPriceInputOpened()
    },
    onPriceInputClick (item: string) {
      this.priceInputOpened = {
        ...clearPriceInputOpened(),
        [item]: true,
      }
      this.$nextTick(() => {
        if (this.$refs[item]?.$el) {
          this.$refs[item].$el.querySelector('input')?.focus()
        }
      })
    },
    onClickAway (item: string) {
      if (this.priceInputOpened[item]) {
        this.priceInputOpened = {
          ...clearPriceInputOpened()
        }
      }
    },
    handleInputPrice (item: string, value: number) {
      let [from, to] = this.value
      if (item === 'from') {
        from = value
      } else {
        to = value
      }
      if (from > MAX_BUDGET) {
        from = to = MAX_BUDGET
      }
      if (to > MAX_BUDGET) {
        to = MAX_BUDGET
      }
      if (from > to) {
        to = (from + 2) > MAX_BUDGET ? MAX_BUDGET : (from + 2)
      }
      this.$emit('input', [from, to])
    },
    onSliderChangeBudget (value: SalaryMenuItem) {
      this.$emit('input', value)
    },
  },
})
