import Vue, { PropType } from 'vue'
import Skill from '@/models-ts/Skill'
import { MultipleMenuItem } from '../types'

export default Vue.extend<any, any, any, any>({
  props: {
    title: String,
    isLoading: Boolean,
    menuItems: Array as PropType<Array<MultipleMenuItem>>,
    value: Array as PropType<Array<MultipleMenuItem>>,
  },
  data () {
    return {
      isMenuOpened: false,
      tempItems: [],
    }
  },
  computed: {
    isMenuActive () {
      return this.value.length > 0
    },
  },
  watch: {
    value: {
      handler () {
        this.tempItems = [...this.value]
      },
      immediate: true,
    }
  },
  methods: {
    onShow () {
      this.isMenuOpened = true
    },
    onHide () {
      this.isMenuOpened = false
      this.tempItems = [...this.value]
    },
    isItemSelected (item: Skill) {
      return this.tempItems.some((id: number) => id === item.id)
    },
    onItemInput (item: Skill) {
      const i = this.tempItems.findIndex((s: string) => +s === item.id)
      if (i !== -1) {
        this.tempItems.splice(i, 1)
      } else {
        this.tempItems.push(item.id)
      }
    },
    onItemsClear () {
      this.tempItems = []
    },
    onItemsApply () {
      this.$emit('input', [...this.tempItems])
      this.$refs?.menuRef?.closeMenu()
    },
  },
})
