import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import debounce from 'lodash/debounce'
import pickBy from 'lodash/pickBy'
import cloneDeep from 'lodash/cloneDeep'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { formatUsd } from '@/utils/moneyFormat'
import { BROWSE_VACANCIES_BY_ROLE, VACANCIES } from '@/constants/routes'
import { RootState } from '@/store'
import Skill from '@/models-ts/Skill'
import { RoleOption } from '@/store/shared/modules/vacancyRoles/types'
import CategoriesSelect from '../components/CategoriesSelect/CategoriesSelect.vue'
import BudgetSelect from '../components/BudgetSelect/BudgetSelect.vue'
import SortSelect from '../components/SortSelect/SortSelect.vue'
import { SORT_OPTIONS, MAX_BUDGET, MIN_BUDGET } from '../components/constants'
import { SalaryMenuItem, SortMenuItem } from '../components/types'

export default Vue.extend<any, any, any, any>({
  components: {
    CategoriesSelect,
    BudgetSelect,
    SortSelect,
  },
  data () {
    return {
      MIN_BUDGET,
      MAX_BUDGET,
      SORT_OPTIONS,
      search: '',
      sortValue: null,
      budget: [0, MAX_BUDGET],
      selectedCategories: [],
      selectedRoles: [],
      role: null,
    }
  },
  computed: {
    ...mapState<RootState>({
      rolesLoaded: (state: RootState) => state.vacancyRoles.roles.isLoaded,
      rolesLoading: (state: RootState) => state.vacancyRoles.roles.isLoading,
      predefinedSkills: (state: RootState) => state.skills.skills.value,
      skillsLoading: (state: RootState) => state.skills.skills.isLoading,
    }),
    ...mapGetters({
      getRoleByURL: 'vacancyRoles/getRoleByURL',
      roles: 'vacancyRoles/roleOptions',
      categories: 'skills/getCategories',
    }),
    roleOptions () {
      return this.roles.filter((role: RoleOption) => !role.isCategory)
    },
    tags () {
      const searchTag = this.search
        ? [{
          text: `Search: ${this.search}`,
          type: 'search',
        }]
        : []

      const roleTags = this.selectedRoles.map((id: number) => {
        const role = this.roleOptions.find((role: RoleOption) => role.id === id)
        if (role) {
          return {
            text: `Role: ${role.name}`,
            type: 'role',
            content: role,
          }
        }
      }).filter(Boolean)
      const skillTags = this.selectedCategories.map((id: number) => {
        const skill = this.predefinedSkills.find((skill: Skill) => skill.id === id)
        if (skill) {
          return {
            text: `Category: ${skill.name}`,
            type: 'skill',
            content: skill,
          }
        }
      }).filter(Boolean)
      const sortTags = this.sortValue?.name && this.sortValue?.queryValue !== 'date-desc'
        ? [{
          text: `Sort by: ${this.sortValue?.name}`,
          type: 'sort',
        }]
        : []

      const bFrom = !Number.isNaN(this.budget[0]) && this.budget[0] > MIN_BUDGET
      const bTo = !Number.isNaN(this.budget[1]) && this.budget[1] > MIN_BUDGET && this.budget[1] !== MAX_BUDGET
      const priceTags = bFrom || bTo
        ? [{
          text: `Budget: $${formatUsd(this.budget[0])} to $${formatUsd(this.budget[1])}`,
          type: 'price',
        }]
        : []
      return [...searchTag, ...sortTags, ...skillTags, ...roleTags, ...priceTags]
    },
    hasFilter () {
      return this.search ||
        (this.sortValue?.value && this.sortValue?.queryValue !== 'date-desc') ||
        this.selectedCategories.length > 0 ||
        this.selectedRoles.length > 0 ||
        this.budget[0] > MIN_BUDGET ||
        this.budget[1] < MAX_BUDGET
    },
    filtersCount () {
      return 0 + (this.search ? 1 : 0) +
        (this.sortValue?.value && this.sortValue?.queryValue !== 'date-desc' ? 1 : 0) +
        (this.selectedCategories.length > 0 ? 1 : 0) +
        (this.selectedRoles.length > 0 ? 1 : 0) +
        (this.budget[0] > MIN_BUDGET || this.budget[1] < MAX_BUDGET ? 1 : 0)
    },
  },
  mounted () {
    this.initFiltersFromQuery()
  },
  watch: {
    async $route (newR, oldR) {
      if (newR.query !== oldR.query) {
        this.initFiltersFromQuery()
      }
    },
  },
  methods: {
    formatUsd,
    onInputSearch: debounce(function (this: any) {
      googleAnalyticsV2.send({
        event: 'vacancies-search',
      })
      this.mapToQuery()
    }, 500),
    onSortInput (sort: SortMenuItem) {
      googleAnalyticsV2.send({
        event: 'vacancies-sort-click',
        'event-content': sort.value.trim(),
      })
      this.sortValue = sort
      this.mapToQuery()
    },
    onCategoriesSelect (selectedCategories: Array<Skill>) {
      this.selectedCategories = selectedCategories
      this.mapToQuery()
    },
    onRolesSelect (selectedRoles: Array<RoleOption>) {
      this.selectedRoles = selectedRoles
      this.mapToQuery()
    },
    onBudgetSelect (budget: SalaryMenuItem) {
      this.budget = budget
      this.mapToQuery()
    },
    onClickReset () {
      this.search = ''
      this.sortValue = SORT_OPTIONS[0]
      this.budget = [MIN_BUDGET, MAX_BUDGET]
      this.selectedCategories = []
      this.selectedRoles = []
      this.mapToQuery()
    },
    onTagDelete ({ tag }: { tag: any }) {
      switch (tag.type) {
        case 'search': {
          this.search = ''
          break
        }
        case 'sort': {
          this.sortValue = null
          break
        }
        case 'price': {
          this.budget = [MIN_BUDGET, MAX_BUDGET]
          break
        }
        case 'role': {
          const roleIndex = this.selectedRoles.findIndex((s: string) => +tag.content.id === +s)
          if (roleIndex !== -1) {
            const selectedRoles = cloneDeep(this.selectedRoles)
            selectedRoles.splice(roleIndex, 1)
            this.selectedRoles = selectedRoles
          }
          break
        }
        default: {
          const skillIndex = this.selectedCategories.findIndex((s: string) => +tag.content.id === +s)
          if (skillIndex !== -1) {
            const selectedCategories = cloneDeep(this.selectedCategories)
            selectedCategories.splice(skillIndex, 1)
            this.selectedCategories = selectedCategories
          }
        }
      }
      this.mapToQuery()
    },
    initFiltersFromQuery () {
      const {
        title = '',
        orderField = null,
        orderType = null,
        budgetFrom = MIN_BUDGET,
        budgetTo = MAX_BUDGET,
        skill = [],
        role = [],
      } = this.$route.query
      this.search = title
      this.sortValue = (orderField && orderType)
        ? SORT_OPTIONS.find(s => s.value === orderField && s.direction === orderType)
        : SORT_OPTIONS[0]
      this.budget = [Number(budgetFrom), Number(budgetTo)]

      this.selectedCategories = []
      if (this.$route.params.skill) {
        const mainSkill = this.predefinedSkills.find((opt: any) => opt.url === this.$route.params.skill)
        if (mainSkill) {
          this.selectedCategories.push(mainSkill.id)
        }
      }
      this.selectedCategories = this.selectedCategories.concat((Array.isArray(skill) ? skill : [skill])
        .map((s: string) => +s)
        .filter(s => this.predefinedSkills.find((opt: any) => +opt.id === s)))
      this.selectedCategories = [...new Set(this.selectedCategories)]

      this.selectedRoles = []
      if (this.$route.params.role) {
        const mainRole = this.getRoleByURL(this.$route.params.role)
        if (mainRole) {
          this.selectedRoles.push(mainRole.id)
        }
      }
      this.selectedRoles = this.selectedRoles.concat((Array.isArray(role) ? role : [role])
        .map((s: string) => +s)
        .filter(s => this.roleOptions.find((opt: RoleOption) => +opt.id === s)))
      this.selectedRoles = [...new Set(this.selectedRoles)]
    },
    debouncedMTQ: debounce(function (this: any) {
      this.mapToQuery()
    }, 500),
    mapToQuery () {
      const filters = cloneDeep(pickBy({
        ...this.$route.query,
        title: this.search,
        orderField: this.sortValue?.value,
        orderType: this.sortValue?.direction,
        budgetFrom: this.budget[0],
        budgetTo: this.budget[1],
        skill: this.selectedCategories,
        role: this.selectedRoles,
        page: '',
      }, Boolean))
      let routeName = this.$route.name
      let routeParams = cloneDeep(this.$route.params)

      if (routeName === BROWSE_VACANCIES_BY_ROLE) {
        const mainRole = this.getRoleByURL(this.$route.params.role)
        if (!this.selectedRoles.length || !this.selectedRoles.find((catId: number) => catId === mainRole.id)) {
          routeName = VACANCIES
          routeParams = {}
        }
      } else if (this.$route.params.skill) {
        const mainSkill = this.predefinedSkills.find((opt: any) => opt.url === this.$route.params.skill)
        if (!this.selectedCategories.length || !this.selectedCategories.find((catId: number) => catId === mainSkill.id)) {
          routeName = VACANCIES
          routeParams = {}
        }
      }
      this.$router.replace({
        name: routeName,
        params: routeParams,
        query: filters,
      }).catch(() => {})
    },
  },
})
